import 'bootstrap-icons/bootstrap-icons.svg';
import { Collapse, Modal, Offcanvas, Toast, Tooltip } from 'bootstrap';
import Alpine from 'alpinejs';
import cookieNotice from './alpine/data/cookie-notice';
import documentList from './alpine/data/document-list';
import Precognition from 'laravel-precognition-alpine';

window.bootstrap = window.bootstrap || {};
window.bootstrap.Collapse = Collapse;
window.bootstrap.Modal = Modal;
window.bootstrap.Offcanvas = Offcanvas;

document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach((element) => new Tooltip(element));
document.querySelectorAll('.toast').forEach((element) => new Toast(element).show());

Alpine.data('cookieNotice', cookieNotice);
Alpine.data('documentList', documentList);

Alpine.plugin(Precognition);

Alpine.start();
