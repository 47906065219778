declare let gtag: Function;

export default () => ({
    trackDownload(event: Event) {
        if ('gtag' in window) {
            let anchor = event.target as HTMLElement;

            gtag('event', 'select_item', {
                item_list_id: 'documents',
                item_list_name: 'Documents',
                items: [
                    {
                        item_id: anchor.getAttribute('data-document-id'),
                        item_name: anchor.textContent,
                        item_list_id: 'documents',
                        item_list_name: 'Documents',
                    },
                ],
            });
        }
    },
});
