import axios from '../../plugins/axios';

type Consent = 'default' | 'granted' | 'denied';

export default () => ({
    consent: 'default' as Consent,
    processing: false,
    successful: false,

    onSubmit(event: Event) {
        const form = event.target as HTMLFormElement;
        const data = new FormData(form);

        this.processing = true;

        axios
            .post(form.action, data)
            .then(() => {
                this.consent = data.get('analytics_storage') === 'granted' ? 'granted' : 'denied';

                window.gtag('consent', 'update', {
                    'ad_storage': this.consent,
                    'analytics_storage': this.consent,
                });
            })
            .catch((error) => {
                let message = 'Error updating cookie preferences. Please try again later.';

                if (error.response.data && error.response.data.message) {
                    message = error.response.data.message;
                }

                window.alert(message);
            })
            .finally(() => {
                this.processing = false;
            });
    },
});
